<template>
    <el-dialog
        title="重置密码"
        :visible.sync="resetPasswordDialogVisible"
        width="30%"
        :center="true"
        :append-to-body="true"
        :close-on-click-modal="false"
        :before-close="() => closeResetPasswordDialog('resetPasswordForm')"
    >
        <el-form
            :model="password"
            :rules="rulesPassword"
            ref="resetPasswordForm"
            label-width="100px"
        >
            <el-form-item label="新密码" prop="newPassword">
                <el-input
                    v-model="password.newPassword"
                    show-password
                ></el-input>
            </el-form-item>
            <el-form-item label="确认新密码" prop="confirmPassword">
                <el-input
                    v-model="password.confirmPassword"
                    show-password
                ></el-input>
            </el-form-item>
        </el-form>
        <div slot="footer">
            <el-button
                type="success"
                plain
                @click="submitResetPassword('resetPasswordForm')"
                >确定</el-button
            >
            <el-button
                plain
                @click="closeResetPasswordDialog('resetPasswordForm')"
                >取消</el-button
            >
        </div>
    </el-dialog>
</template>

<script>
import { staff_reset_password_request } from '@/network/staff.js'

export default {
    name: 'resetPasswordDialogComponent',
    data() {
        return {
            password: {
                newPassword: '',
                confirmPassword: '',
            },
            rulesPassword: {
                newPassword: [
                    {
                        required: true,
                        message: '请填写新密码',
                        trigger: 'blur',
                    },
                ],
                confirmPassword: [
                    {
                        required: true,
                        message: '请填写确认密码',
                        trigger: 'blur',
                    },
                ],
            },
        }
    },
    computed: {},
    methods: {
        closeResetPasswordDialog(formName) {
            this.$emit('exitResetPasswDialog')
            this.$refs[formName].resetFields()
        },
        submitResetPassword(formName) {
            this.$refs[formName].validate((valid) => {
                if (!valid) {
                    return
                }
                if (
                    this.password.newPassword !== this.password.confirmPassword
                ) {
                    this.$message.error('两次新密码不一致')
                    return
                }
                const data = {
                    id: this.id,
                    new_password: this.password.newPassword,
                    ok_password: this.password.confirmPassword,
                }
                this.$emit('exitResetPasswDialog')
                staff_reset_password_request(data)
                    .then((s) => {
                        if (s.status === 0) {
                            this.$message.success('修改成功!')
                        } else {
                            this.$message.error(s.msg)
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    })
                    .finally(() => this.closeResetPasswordDialog(formName))
            })
        },
    },
    filters: {},
    props: {
        resetPasswordDialogVisible: Boolean,
        id: Number,
    },
    created() {},
    destoryed() {},
    mounted() {},
    updated() {},
    components: {},
}
</script>

<style lang='less'></style>
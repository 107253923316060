<template>
    <div>
        <el-dialog
            :title="dialogFormTitle"
            :visible.sync="dialogFormVisible"
            :append-to-body="true"
            width="50%"
            :center="true"
            top="15vh"
            :close-on-click-modal="false"
            :before-close="() => closeStaffDialog('staffEditForm')"
            v-loading.fullscreen.lock="login_loading"
        >
            <div>
                <el-form
                    :model="staff"
                    :rules="rules"
                    ref="staffEditForm"
                    label-width="100px"
                    size="small"
                >
                    <div v-if="show_type">
                        <el-form-item label="用户名" prop="username">
                            <el-input
                                v-model="staff.username"
                                :disabled="usernameDisabled"
                                clearable
                            ></el-input>
                        </el-form-item>
                        <el-form-item label="姓名" prop="name">
                            <el-input v-model="staff.name" clearable></el-input>
                        </el-form-item>
                        <el-form-item label="性别" prop="sex">
                            <el-radio-group v-model="staff.sex">
                                <el-radio
                                    v-for="(v, i) in this.$store.state.sex"
                                    :label="i"
                                    :key="i"
                                    >{{ v }}</el-radio
                                >
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item label="邮箱" prop="email">
                            <el-input v-model="staff.email" clearable></el-input>
                        </el-form-item>
                        <el-form-item label="手机号" prop="phone">
                            <el-input v-model="staff.phone" clearable></el-input>
                        </el-form-item>
                        <el-form-item label="身份证号" prop="icard">
                            <el-input v-model="staff.icard" clearable></el-input>
                        </el-form-item>
                        <el-form-item label="住址" prop="address">
                            <el-input v-model="staff.address" clearable></el-input>
                        </el-form-item>
                        <el-form-item label="学历" prop="education">
                            <el-select
                                v-model="staff.education"
                                placeholder="请选择学历"
                                filterable
                                clearable
                                class="educationc"
                            >
                                <el-option
                                    v-for="(v, i) in this.$store.state.education"
                                    :label="v"
                                    :value="i"
                                    :key="i"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="婚姻状态" prop="marriage">
                            <el-radio-group v-model="staff.marriage">
                                <el-radio
                                    v-for="(v, i) in this.$store.state.marriage"
                                    :label="i"
                                    :key="i"
                                    >{{ v }}</el-radio
                                >
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item  label="小孩出生日期" prop="children_date">
                            <el-date-picker                            
                                v-model="staff.children_date"
                                align="right"
                                type="date"
                                placeholder="选择日期"
                                value-format="yyyy-MM-dd"
                                class="children_datec"
                            >
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item label="职位" prop="position_id">
                            <el-cascader
                                v-model="staff.position_id"
                                placeholder="请选择职位"
                                :options="enterprise_department_position"
                                :props="{ expandTrigger: 'hover' }"
                                clearable
                                style="width: 100%"
                            >
                            </el-cascader>
                        </el-form-item>
                        <el-form-item label="入职日期" prop="entry_date">
                            <el-date-picker
                                v-model="staff.entry_date"
                                align="right"
                                type="date"
                                placeholder="选择日期"
                                value-format="yyyy-MM-dd"
                                class="entry_datec"
                            >
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item
                            v-if="id"
                            label="离职日期"
                            prop="separation_date"
                        >
                            <el-date-picker
                                v-model="staff.separation_date"
                                align="right"
                                type="date"
                                placeholder="选择日期"
                                value-format="yyyy-MM-dd"
                                class="separation_date"
                            >
                            </el-date-picker>
                        </el-form-item>
                    </div>
                    <div v-else>
                        <el-descriptions
                            class="margin-top"
                            :column="2"
                            :labelStyle="{'width':'120px','text-align':'center'}"
                            :contentStyle="{'text-align': 'center'}"
                            border
                        >
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>用户名</span>
                                </template>
                                <span>{{staff.username}}</span>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>姓名</span>
                                </template>
                                <span>{{staff.name}}</span>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>性别</span>
                                </template>
                                <span>{{staff.sex === 0 ? '男' : (staff.sex === 1 ? '女' : '')}}</span>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>邮箱</span>
                                </template>
                                <span>{{staff.email}}</span>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>手机号</span>
                                </template>
                                <span>{{staff.phone}}</span>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>身份证号</span>
                                </template>
                                <span>{{staff.icard}}</span>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>住址</span>
                                </template>
                                <span>{{staff.address}}</span>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>学历</span>
                                </template>
                                <span>{{this.$store.state.education[staff.education]}}</span>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>婚姻状态</span>
                                </template>
                                <span>{{this.$store.state.marriage[staff.marriage]}}</span>
                            </el-descriptions-item>
                            <el-descriptions-item  >
                                <template slot="label">
                                    <span>小孩出生日期</span>
                                </template>
                                <span>{{staff.children_date}}</span>
                            </el-descriptions-item>
                            <el-descriptions-item >
                                <template slot="label">
                                    <span>微信状态</span>
                                </template>
                                <el-tag v-if="staff.wx_id" type="success">已绑定</el-tag>
                                <el-tag v-else type="danger">未绑定</el-tag>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>入职日期</span>
                                </template>
                                <span>{{staff.entry_date}}</span>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>离职日期</span>
                                </template>
                                <span>{{staff.separation_date}}</span>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>职位</span>
                                </template>
                                <span>{{staff.enterprise_name + '/' + staff.department_name + '/' + staff.position_name}}</span>
                            </el-descriptions-item>
                        </el-descriptions>
                    </div>
                </el-form>
            </div>
            <div slot="footer" class="dialog-footer">
                <div v-if="show_type===0">
                    <el-button
                        v-if="$_has(edit_auth) && (id !== 1)"
                        type="primary"
                        plain
                        @click="$emit('show_edit')"
                        >修改</el-button
                    >
                    <el-button
                        v-if="$_has(password_auth) && (id !== 1)"
                        type="warning"
                        plain
                        @click="fresetPasswordDialogVisible = true"
                        >重置密码</el-button
                    >
                    <el-button
                        v-if="$_has(del_auth) && (id !== 1)"
                        type="danger"
                        plain
                        @click="staffDel('staffEditForm')"
                        >删除</el-button
                    >
                    <el-button
                        plain
                        @click="closeStaffDialog('staffEditForm')"
                        >关闭</el-button
                    >
                </div>
                <div v-else-if="show_type===1">
                    <el-button
                        type="success"
                        plain
                        @click="submitInsert('staffEditForm')"
                        >确定</el-button
                    >
                    <el-button
                        type="warning"
                        plain
                        @click="resetForm('staffEditForm')"
                        >重置</el-button
                    >
                    <el-button
                        plain
                        @click="closeStaffDialog('staffEditForm')"
                        >关闭</el-button
                    >
                </div>
                <div v-else>
                    <el-button
                        type="success"
                        plain
                        @click="submitEdit('staffEditForm')"
                        >确定修改</el-button
                    >
                    <el-button
                        plain
                        @click="closeStaffDialog('staffEditForm')"
                        >取消操作</el-button
                    >
                </div>
            </div>
            <reset-password-dialog-component
                :id="id"
                :resetPasswordDialogVisible="
                    fresetPasswordDialogVisible
                "
                @exitResetPasswDialog="
                    fresetPasswordDialogVisible = false
                "
            ></reset-password-dialog-component>
        </el-dialog>
    </div>
</template>

<script>
import { staff_add_request,staff_del_request,staff_edit_request } from '@/network/staff.js'

import resetPasswordDialogComponent from './resetPasswordDialogComponent'

export default {
    name: 'StaffEditComponent',
    data() {
        return {
            login_loading: false,
            fresetPasswordDialogVisible: false,
            staff: {
                username: '',
                name: '',
                sex: '',
                email: '',
                phone: '',
                icard: '',
                address: '',
                education: '',
                marriage: '',
                position_id: '',
                position_name: '',
                department_name: '',
                enterprise_name: '',
                children_date: '',
                entry_date: '',
                separation_date: '',
            },
            rules: {
                username: [
                    {
                        required: true,
                        message: '请输入用户名',
                        trigger: 'blur',
                    },
                    {
                        min: 3,
                        max: 16,
                        message: '长度在 3 到 16 个字符',
                        trigger: 'blur',
                    },
                ],
                name: [
                    { required: true, message: '请输入姓名', trigger: 'blur' },
                    {
                        min: 2,
                        max: 8,
                        message: '长度在 2 到 8 个字符',
                        trigger: 'blur',
                    },
                ],
                sex: [
                    { required: true, message: '请选择性别', trigger: 'blur' },
                ],
                email: [
                    {
                        type: 'email',
                        message: '邮箱格式不正确',
                        trigger: 'blur',
                    },
                ],
                phone: [
                    {
                        pattern: /^1[3,5,6,7,8,9]\d{9}$/,
                        message: '手机号格式不正确',
                        trigger: 'blur',
                    },
                ],
                icard: [
                    {
                        required: true,
                        message: '请填写身份证号',
                        trigger: 'blur',
                    },
                    {
                        pattern: /^\d{17}[\d,'x','X']$/,
                        message: '身份证号格式不正确',
                        trigger: 'blur',
                    },
                ],
                address: [
                    { required: true, message: '住址必填', trigger: 'blur' },
                ],
                education: [
                    { required: true, message: '请选择学历', trigger: 'blur' },
                ],
                marriage: [
                    {
                        required: true,
                        message: '请选择婚姻状态',
                        trigger: 'blur',
                    },
                ],
                children_date: [
                    {
                        required: false,
                        message: '请选择小孩出生日期',
                        trigger: 'blur',
                    },
                ],
                entry_date: [
                    {
                        required: true,
                        message: '请选择入职日期',
                        trigger: 'blur',
                    },
                ],
                position_id: [
                    { required: true, message: '请选择职位', trigger: 'blur' },
                ],
            },
        }
    },
    computed: {
        dialogFormTitle() {
            if(this.show_type === 0) {
                return '用户详情'
            } else if (this.show_type === 1) {
                return '添加用户'
            } else {
                return '修改用户'
            }
        },
        usernameDisabled() {
            return this.id ? true : false
        },
    },
    methods: {
        submitInsert(formName) {
            this.$refs[formName].validate((valid) => {
                if (!valid) {
                    return
                }
                this.staffInsert(formName)
            })
        },
        resetForm(formName) {
            this.staff = {
                username: '',
                name: '',
                sex: '',
                email: '',
                phone: '',
                icard: '',
                address: '',
                education: '',
                marriage: '',
                position_id: '',
                position_name: '',
                department_name: '',
                enterprise_name: '',
                children_date:'',
                entry_date: '',
                separation_date: '',
            }
        },
        closeStaffDialog(formName) {
            this.staff = {
                username: '',
                name: '',
                sex: '',
                email: '',
                phone: '',
                icard: '',
                address: '',
                education: '',
                marriage: '',
                position_id: '',
                position_name: '',
                department_name: '',
                enterprise_name: '',
                children_date:'',
                entry_date: '',
                separation_date: '',
            }
            this.$emit('exitStaffDialog')
        },
        staffInsert(formName) {
            this.login_loading = true
            let d = this.staff
            d.position_id = d.position_id[d.position_id.length - 1]
            staff_add_request(d)
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.$alert(
                            '添加成功，初始默认密码为【' + s.result.password + '】，请通知用户自行修改密码',
                            '温馨提示',
                            {
                                confirmButtonText: '确定',
                                callback: (action) => {
                                    this.$emit('exitStaffDialog')
                                    this.$emit('search')
                                    this.$emit('details_row',s.result.id*1)
                                },
                            }
                        )
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
        },
        staffDel(formName) {
            this.$confirm(
                '是否确定删除？（一经删除，将无法再进行恢复，请谨慎操作！）',
                '温馨提示',
                {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }
            )
                .then(() => {
                    staff_del_request(this.id)
                        .then((s) => {
                            if (s.status === 0) {
                                this.$message.success('删除成功!')
                                this.$emit('search')
                            } else {
                                this.$message.error(s.msg)
                            }
                        })
                        .catch((err) => {
                            this.$message.error(err)
                        })
                        .finally(() => this.closeStaffDialog(formName))
                })
                .catch((err) => {})
        },
        submitEdit(formName) {
            this.$refs[formName].validate((valid) => {
                if (!valid) {
                    return
                }
                this.staffEdit(formName)
            })
        },
        staffEdit(formName) {
            this.login_loading = true
            let d = this.staff
            d.position_id = d.position_id[d.position_id.length - 1]
            d.id = this.id
            staff_edit_request(d)
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.$message.success('修改成功!')
                        this.$emit('exitStaffDialog')
                        this.$emit('search')
                        this.$emit('details_row',this.staff.id)
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
        },
    },
    filters: {},
    created() {},
    destoryed() {},
    mounted() {},
    updated() {},
    watch: {
        staff_details_data(newVal) {
            this.staff = newVal
        },
    },
    components: {
        resetPasswordDialogComponent,
    },
    props: {
        dialogFormVisible: Boolean,
        id: {
            type: Number,
            default() {
                return 0
            },
        },
        staff_details_data: {
            type: Object,
            default() {
                return {}
            },
        },
        show_type: {
            type:Number,
            default() {
                return 0
            },
        },
        enterprise_department_position: Array,
        edit_auth: String,
        del_auth: String,
        password_auth: String
    },
}
</script>

<style>
.el-date-editor.el-input {
    width: 100%;
}

.entry_datec,.educationc {
    width: 100%;
}
</style>
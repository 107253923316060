<template>
    <div class="StaffViewContainer" v-loading.fullscreen.lock="loading">
        <staff-search-bar-component
            add_auth="添加员工档案"
            :fAllenterprise="fAllenterprise"
            @search="get_staff_index"
            @addTeam="addTeam"
        ></staff-search-bar-component>
        <common-table-component
            details_auth="查看员工档案详情"
            :col_arr="fcol_arr"
            :table_data="ftable_data"
            @details_row="details_staff"
        ></common-table-component>
        <common-page-component
            :current_page="cond.page"
            :page_size="cond.limit"
            :total="ftotal"
            @search="get_staff_index"
        ></common-page-component>
        <staff-edit-component
            edit_auth="修改员工档案"
            del_auth="删除员工档案"
            password_auth="修改员工登录密码"
            :id="fid"
            :show_type="edit_type"
            :dialogFormVisible="fdialogFormVisible"
            :staff_details_data="fstaff_details_data"
            :enterprise_department_position="enterprise_department_position"
            @exitStaffDialog="dialogExit"
            @search="get_staff_index"
            @show_edit="show_edit"
            @details_row="details_staff"
        ></staff-edit-component>
    </div>
</template>
<script>
import { staff_index_request,staff_details_request } from '@/network/staff.js'
import { enterprise_department_position_request} from '@/network/enterprise.js'
import { enterprise_list_request} from '@/network/list.js'

import StaffSearchBarComponent from '@/components/staff/StaffSearchBarComponent'
import CommonTableComponent from '@/components/common/CommonTableComponent'
import CommonPageComponent from '@/components/common/CommonPageComponent'
import StaffEditComponent from '@/components/staff/StaffEditComponent'

export default {
    name: 'StaffView',
    data() {
        return {
            fid: 0,
            edit_type: 0,
            fdialogFormVisible: false,
            loading: true,
            ftotal: 0,
            cond: {
                username: '',
                name: '',
                sex: '',
                enterprise_id: '',
                department_id: '',
                separation_state: '',
                wx_id: '',
                page: 1,
                limit: 10,
            },
            ftable_data: [],
            fcol_arr: [
                {
                    prop: 'head_portrait',
                    label: '头像',
                    minWidth: '100px',
                    img: 'width: 80px;height: 80px;',
                },
                {
                    prop: 'username',
                    label: '用户名',
                    minWidth: '110px'
                },
                {
                    prop: 'name',
                    label: '姓名',
                    minWidth: '100px'
                },
                {
                    prop: 'sex',
                    label: '性别',
                    minWidth: '60px',
                },
                {
                    prop: 'position_name',
                    label: '职位',
                    minWidth: '150px'
                },
                {
                    prop: 'department_name',
                    label: '部门',
                    minWidth: '150px'
                },
                {
                    prop: 'enterprise_name',
                    label: '企业',
                    minWidth: '260px',
                },
                {
                    prop: 'separation_date',
                    label: '状态',
                    minWidth: '100px',
                    tag: (d) => {
                        if (d) {
                            return {
                                type: 'danger',
                                filter_data: '离职',
                            }
                        }

                        return {
                            type: 'success',
                            filter_data: '在职',
                        }
                    },
                },
                {
                    prop: 'wx_id',
                    label: '微信状态',
                    minWidth: '100px',
                    tag: (d) => {
                        if (d) {
                            return {
                                type: 'success',
                                filter_data: '已绑定',
                            }
                        }

                        return {
                            type: 'danger',
                            filter_data: '未绑定',
                        }
                    },
                },
            ],
            fstaff_details_data: {},
            fAllenterprise: [],
            enterprise_department_position:[]
        }
    },
    computed: {},
    methods: {
        addTeam() {
            this.edit_type = 1
            this.fdialogFormVisible = true
        },
        show_edit() {
            this.edit_type = 2
        },
        get_staff_index(param = {}) {
            this.loading = true
            this.cond.username = param.username ?? this.cond.username
            this.cond.name = param.name ?? this.cond.name
            this.cond.sex = param.sex ?? this.cond.sex
            this.cond.enterprise_id = param.enterprise_id ?? this.cond.enterprise_id
            this.cond.department_id = param.department_id ?? this.cond.department_id
            this.cond.separation_state = param.separation_state ?? this.cond.separation_state
            this.cond.wx_id = param.wx_id ?? this.cond.wx_id
            this.cond.page = param.page ?? this.cond.page
            if (param.limit) {
                this.cond.limit = param.limit
                this.cond.page = 1
            }

            staff_index_request(this.cond)
                .then((s) => {
                    this.loading = false
                    if (s.status === 0) {
                        this.ftable_data = s.result.data.map((v) => {
                            v.sex = this.$store.state.sex[v.sex]
                            return v
                        })
                        this.ftotal = s.result.total
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.loading = false
                    this.$message.error(err)
                })
        },
        details_staff(id) {
            this.fid = id
            this.fdialogFormVisible = true
            staff_details_request(id)
                .then((s) => {
                    if (s.status === 0) {
                        this.fstaff_details_data = s.result
                        this.fstaff_details_data.position_id = [
                            s.result.enterprise_id,
                            s.result.department_id,
                            s.result.position_id,
                        ]
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        get_all_enterprise() {
            this.loading = true
            enterprise_list_request()
                .then((s) => {
                    this.loading = false
                    if (s.status === 0) {
                        this.fAllenterprise = s.result
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.loading = false
                    this.$message.error(err)
                })
        },
        get_enterprise_department_position() {
            enterprise_department_position_request()
                .then((s) => {
                    if (s.status === 0) {
                        this.enterprise_department_position = s.result
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.loading = false
                    this.$message.error(err)
                })
        },
        dialogExit() {
            this.fdialogFormVisible = false
            this.fid = 0
            this.edit_type = 0
        },
    },
    filters: {},
    props: {},
    created() {
        this.get_staff_index()
        this.get_all_enterprise()
        this.get_enterprise_department_position()
    },
    destoryed() {},
    mounted() {},
    updated() {},
    components: {
        StaffSearchBarComponent,
        CommonTableComponent,
        CommonPageComponent,
        StaffEditComponent,
    },
}
</script>

<style lang='less'></style>
<template>
    <el-card class="box-card">
        <el-form ref="searchform" :inline="true" :model="form" size="medium">
            <el-form-item label="姓名" prop="name">
                <el-input
                    v-model="form.name"
                    placeholder="姓名搜索"
                    class="userc"
                    @keyup.enter.native="search"
                    clearable
                ></el-input>
            </el-form-item>
            <el-form-item label="性别" prop="sex">
                <el-select
                    v-model="form.sex"
                    placeholder="性别"
                    class="separationc"
                    filterable
                    clearable

                >
                    <el-option label="男" value="0"></el-option>
                    <el-option label="女" value="1"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="用户名" prop="username">
                <el-input
                    v-model="form.username"
                    placeholder="用户名搜索"
                    class="usernamec"
                    @keyup.enter.native="search"
                    clearable
                ></el-input>
            </el-form-item>
            <el-form-item label="企业" prop="enterprise_id">
                <el-select
                    v-model="form.enterprise_id"
                    placeholder="选择企业"
                    class="enterprisec"
                    @change="(enterprise_id) => get_department(enterprise_id)"
                    filterable
                    clearable
                >
                    <el-option
                        v-for="enterprise in fAllenterprise"
                        :key="enterprise.name"
                        :label="enterprise.name"
                        :value="enterprise.id"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="部门" prop="department_id">
                <el-select
                    v-model="form.department_id"
                    placeholder="选择部门"
                    class="departmentc"
                    filterable
                    clearable
                >
                    <el-option
                        v-for="department in this.departmentInEnterprise"
                        :key="department.name"
                        :label="department.name"
                        :value="department.id"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="状态" prop="separation_state">
                <el-select
                    v-model="form.separation_state"
                    placeholder="在职/离职"
                    class="separationc"
                    filterable
                    clearable
                >
                    <el-option label="在职" value="1"></el-option>
                    <el-option label="离职" value="0"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="微信状态" prop="wx_id">
                <el-select
                    v-model="form.wx_id"
                    placeholder="微信状态"
                    class="wx_idc"
                    filterable
                    clearable
                >
                    <el-option label="已绑定" :value="1"></el-option>
                    <el-option label="未绑定" :value="0"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" plain @click="search">搜索</el-button>
                <el-button type="warning" plain @click="resetbtn('searchform')"
                    >重置</el-button
                >
            </el-form-item>
            <el-button
                v-if="$_has(add_auth)"
                type="success"
                plain
                round
                @click="addTeam"
                class="temp_add"
                size="medium"
                >添加用户</el-button
            >
        </el-form>
    </el-card>
</template>

<script>
import { department_list_request } from '@/network/list.js'

export default {
    name: 'StaffSearchBarComponent',
    data() {
        return {
            fdialogFormVisible: false,
            form: {
                username: '',
                name: '',
                sex: '',
                enterprise_id: '',
                department_id: '',
                separation_state: '',
                wx_id: '',
                page: 1,
            },
            departmentInEnterprise: [],
        }
    },
    computed: {},
    methods: {
        search() {
            this.$emit('search', this.form)
        },
        resetbtn(f) {
            this.departmentInEnterprise = []
            this.$refs[f].resetFields()
        },
        addTeam() {
            this.$emit('addTeam')
        },
        get_department(enterprise_id) {
            this.form.department_id = ''
            this.departmentInEnterprise = []
            if (enterprise_id) {
                department_list_request({ enterprise_id })
                    .then((s) => {
                        this.loading = false
                        if (s.status === 0) {
                            this.departmentInEnterprise = s.result
                        } else {
                            this.$message.error(s.msg)
                        }
                    })
                    .catch((err) => {
                        this.loading = false
                        this.$message.error(err)
                    })
            }
        },
    },
    filters: {},
    props: {
        fAllenterprise: Array,
        add_auth: String
    },
    created() {},
    destoryed() {},
    mounted() {},
    updated() {},
    components: {},
}
</script>

<style lang='less'>
.usernamec {
    width: 150px;
}

.userc {
    width: 150px;
}

.enterprisec {
    width: 260px;
}

.departmentc {
    width: 150px;
}

.separationc,.wx_idc {
    width: 150px;
}

.temp_add {
    float: right;
    margin-bottom: 10px;
}
</style>
import request from './request'

//用户列表
export const staff_index_request = p => {
    return request({
        method:'GET',
        url:'staff/index',
        params: p
    })
}

//添加用户
export const staff_add_request = d => {
    return request({
        method:'POST',
        url:'staff/add',
        data: d
    })
}

//删除用户
export const staff_del_request = id => {
    return request({
        method:'DELETE',
        url:'staff/del',
        data: {
            id: id
        }
    })
}

//重置用户密码
export const staff_reset_password_request = data => {
    return request({
        method:'PUT',
        url:'staff/reset_password',
        data
    })
}

//获取用户详情
export const staff_details_request = id => {
    return request({
        method:'GET',
        url:'staff/details',
        params: {
            id
        }
    })
}

//修改用户
export const staff_edit_request = d => {
    return request({
        method:'PUT',
        url:'staff/edit',
        data: {
            id: d.id,
            name: d.name,
            sex: d.sex,
            email: d.email,
            phone: d.phone,
            icard: d.icard,
            address: d.address,
            education: d.education,
            marriage: d.marriage,
            position_id: d.position_id,
            entry_date: d.entry_date,
            separation_date: d.separation_date,
            children_date: d.children_date
        }
    })
}
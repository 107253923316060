import request from './request'

//企业列表
export const enterprise_index_request = p => {
    return request({
        method:'GET',
        url:'enterprise/index',
        params: p
    })
}

//添加企业
export const enterprise_add_request = d => {
    return request({
        method:'POST',
        url:'enterprise/add',
        data: d
    })
}

//删除企业
export const enterprise_del_request = id => {
    return request({
        method:'DELETE',
        url:'enterprise/del',
        data: {
            id: id
        }
    })
}

//修改企业
export const enterprise_edit_request = data => {
    return request({
        method:'PUT',
        url:'enterprise/edit',
        data
    })
}

//获取企业详情
export const enterprise_details_request = id => {
    return request({
        method:'GET',
        url:'enterprise/details',
        params: {
            id
        }
    })
}

//获取企业部门两级联动数据
export const enterprise_department_request = () => {
    return request({
        method:'GET',
        url:'enterprise/department'
    })
}

//获取企业部门职位三级联动数据
export const enterprise_department_position_request = () => {
    return request({
        method:'GET',
        url:'enterprise/department_position'
    })
}

//获取企业仓库两级联动数据
export const enterprise_storeroom_request = () => {
    return request({
        method:'GET',
        url:'enterprise/storeroom'
    })
}